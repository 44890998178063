import React, { useEffect, useState } from 'react';

import 'react-select/dist/react-select.css';
import 'react-virtualized/styles.css';
import 'react-virtualized-select/styles.css';
import VirtualizedSelect from 'react-virtualized-select';
import ApiClientApartment from '../../../API/ApiClientApartment';
import Spinner from '../../shared/Spinner';

interface Props {
  changeValue: CallableFunction;
  defaultValue?: number;
}
const ApartmentDropDown: React.FC<Props> = ({
  changeValue,
  defaultValue,
}: Props) => {
  const [options, setOptions] = useState<{ value: number; label: string }[]>();
  const [selection, setSelection] = useState<undefined | number>(defaultValue);

  const fetchApartments = async (mounted: boolean) => {
    const apartments = await ApiClientApartment.getAllApartments();
    if (mounted) {
      const parsed: { value: number; label: string }[] = [];
      apartments.forEach((apartment) => {
        parsed.push({
          value: apartment.id,
          label: apartment.name,
        });
      });
      setOptions(parsed);
    }
  };

  useEffect(() => {
    let didMount = true;
    fetchApartments(didMount);
    return () => {
      didMount = false;
    };
  }, []);

  if (options === undefined) {
    return <Spinner />;
  }
  return (
    <>
      {options && (
        <VirtualizedSelect
          options={options}
          onChange={(selectValue) => {
            if (selectValue === null) {
              setSelection(undefined);
              changeValue(undefined);
            } else {
              // maybe typing error in the lib
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              setSelection(selectValue.value);
              // eslint-disable-next-line @typescript-eslint/ban-ts-comment
              // @ts-ignore
              changeValue(selectValue.value);
            }
          }}
          value={selection}
        />
      )}
    </>
  );
};

export default ApartmentDropDown;

ApartmentDropDown.defaultProps = {
  defaultValue: undefined,
};
