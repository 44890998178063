import { CCardBody, CCardHeader } from '@coreui/react';
import React from 'react';
import ApartmentList from '../Components/Apartment/ApartmentList';
import CardWrapper from '../Components/shared/CardWrapper';

const Apartment: React.FC = () => {
  return (
    <CardWrapper>
      <CCardHeader>
        <h3>Apartments</h3>
      </CCardHeader>
      <CCardBody>
        <ApartmentList />
      </CCardBody>
    </CardWrapper>
  );
};

export default Apartment;
