import {
  CButton,
  CFormGroup,
  CInput,
  CInputCheckbox,
  CInputGroup,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import ReactLanguageSelect from 'react-languages-select';
import ApiClientBooking, { IBooking } from '../../../API/ApiClientBooking';
import ApartmentDropDown from '../components/ApartmentDropDown';

import '../style.scss';

interface Props {
  visible: boolean;
  onClose: (x: boolean) => void;
  onSuccess: CallableFunction;
  booking: IBooking;

  updateList: (x: IBooking) => void;
}
const UpdateBookingModal: React.FC<Props> = ({
  visible,
  onClose,
  onSuccess,
  updateList,
  booking,
}: Props) => {
  const [newDate, setNewDate] = useState(booking.bookingDate);
  const [newDateEnd, setNewDateEnd] = useState(booking.bookingDateEnd);
  const [newmobileNumber, setNewMobileNumber] = useState(booking.mobileNumber);
  const [newName, setNewName] = useState(booking.name);
  const [newEmail, setNewEmail] = useState(booking.email);
  const [newLanguage, setNewLanguage] = useState(
    booking.language.toUpperCase(),
  );
  const [newApartment, setNewApartment] = useState(booking.apartment.id);
  const [newNumberOfGuests, setNewNumberOfGuests] = useState(
    booking.numberOfGuests,
  );
  const [newExtensionPossbible, setNewExtensionPossbible] = useState(
    booking.extensionPossible,
  );

  useEffect(() => {
    setNewName(booking.name);
    setNewMobileNumber(booking.mobileNumber);
    setNewDate(booking.bookingDate);
    setNewDateEnd(booking.bookingDateEnd);
    setNewEmail(booking.email);
    setNewLanguage(booking.language);
    setNewApartment(booking.apartment.id);
    setNewNumberOfGuests(booking.numberOfGuests);
    setNewExtensionPossbible(booking.extensionPossible);
  }, [booking]);

  const handleUpdate = async () => {
    if (
      booking.id &&
      newDate &&
      newDateEnd &&
      newEmail &&
      newLanguage &&
      newmobileNumber &&
      newName &&
      newNumberOfGuests
    ) {
      const res = await ApiClientBooking.updateBooking(
        booking.id,
        newDate,
        newDateEnd,
        newmobileNumber,
        newName,
        newEmail,
        newLanguage,
        newApartment,
        newExtensionPossbible,
        newNumberOfGuests,
      );
      onClose(false);
      updateList(res);
      onSuccess();
    }
  };
  return (
    <CModal
      size="xl"
      centered
      show={visible}
      onClose={() => {
        onClose(false);
      }}>
      <CModalHeader closeButton>Buchung anpassen</CModalHeader>

      {booking && (
        <CModalBody>
          <CFormGroup className="inputfield">
            <CLabel>Sprache der E-Mail:</CLabel>
            <ReactLanguageSelect
              defaultLanguage={booking.language}
              searchable
              names="international"
              onSelect={(e: string) => setNewLanguage(e)}
            />
          </CFormGroup>

          <CFormGroup className="inputfield">
            <CLabel>Name:</CLabel>
            <CInput
              type="text"
              value={newName}
              id="nf-name"
              title="nf-name"
              placeholder="Name"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewName(e.target.value);
              }}
            />
            <CLabel className="mt-2">Email:</CLabel>
            <CInput
              type="text"
              value={newEmail}
              id="nf-name"
              title="nf-name"
              placeholder="Name"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewEmail(e.target.value);
              }}
            />
          </CFormGroup>
          <CFormGroup className="inputfield">
            <CLabel htmlFor="nf-mobilenumber">Telefonnummer:</CLabel>
            <CInput
              type="phone"
              value={newmobileNumber}
              id="nf-mobilenumber"
              title="nf-mobilenumber"
              placeholder="Telefonnummer"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewMobileNumber(e.target.value);
              }}
            />
          </CFormGroup>

          <CFormGroup>
            <div className="d-flex col pl-0">
              <div className="mr-2">
                <CLabel>Von:</CLabel>
                <CInput
                  required
                  type="date"
                  value={newDate}
                  id="nf-bookingDate"
                  title="nf-bookingDate"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setNewDate(e.target.value);
                  }}
                />
              </div>
              <div>
                <CLabel>Bis:</CLabel>
                <CInput
                  required
                  type="date"
                  value={newDateEnd}
                  id="nf-bookingEndDate"
                  title="nf-bookingEndDate"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setNewDateEnd(e.target.value);
                  }}
                />
              </div>
            </div>
          </CFormGroup>

          <CFormGroup>
            <div className="d-flex col pl-0">
              <div className="mr-2">
                <CLabel>Zahl der Gäste:</CLabel>
                <CInput
                  required
                  type="number"
                  value={newNumberOfGuests || undefined}
                  id="nf-numberOfGuests"
                  title="nf-numberOfGuests"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    setNewNumberOfGuests(parseInt(e.target.value, 10));
                  }}
                />
              </div>
            </div>
          </CFormGroup>

          <CFormGroup className="inputfield">
            <CLabel>Apartment:</CLabel>
            <ApartmentDropDown
              defaultValue={newApartment}
              changeValue={setNewApartment}
            />
          </CFormGroup>

          <CFormGroup className="inputfield">
            <CInputGroup style={{ marginLeft: '1.25rem' }}>
              <CInputCheckbox
                id="nf-extensionPossible"
                label="Verlängerung möglich?"
                checked={newExtensionPossbible}
                onChange={async () => {
                  setNewExtensionPossbible(!newExtensionPossbible);
                }}
              />
              <CLabel>Verlängerung möglich?</CLabel>
            </CInputGroup>
          </CFormGroup>
        </CModalBody>
      )}
      <CModalFooter>
        <CButton
          color="secondary"
          onClick={() => {
            onClose(false);
          }}>
          Abbrechen
        </CButton>
        <CButton
          color="primary"
          onClick={() => {
            handleUpdate();
          }}>
          Anpassen
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default UpdateBookingModal;
