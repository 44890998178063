import { CButton, CDataTable } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';

import useNotification from '../../Hooks/useNotification';
import AddApartmentModal from './modals/AddApartmentModal';
import DeleteApartmentModal from './modals/DeleteApartmentModal';
import UpdateApartmentModal from './modals/UpdateApartmentModal';
import './style.scss';
import Spinner from '../shared/Spinner';
import ApiClientApartment, { IApartment } from '../../API/ApiClientApartment';

const ApartmentList: React.FC = () => {
  const [allApartments, setAllApartments] =
    useState<IApartment[] | undefined>();

  const [showAdd, setShowAdd] = useState(false);
  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [updateItemFocus, setUpdateItemFocus] = useState<IApartment>();
  const [deleteId, setDeleteId] = useState<number>();

  const [notification, showNotification] = useNotification();

  const fields = [
    { key: 'id', label: 'ID' },
    { key: 'name', label: 'Name' },
    { key: 'floor', label: 'Etage' },
    { key: 'street', label: 'Straße' },
    { key: 'zip', label: 'PLZ' },
    { key: 'city', label: 'Ort' },
    { key: 'action', label: '' },
  ];
  const fetchApartments = async (mounted: boolean) => {
    const apartments = await ApiClientApartment.getAllApartments();
    if (mounted) setAllApartments(apartments);
  };
  useEffect(() => {
    let didMount = true;
    fetchApartments(didMount);
    return () => {
      didMount = false;
    };
  }, []);

  const openUpdateModal = (apartment: IApartment) => {
    setUpdateItemFocus(apartment);
    setShowUpdateModal(true);
  };
  const updateLocalList = (newBooking: IApartment) => {
    const newList = allApartments?.map((item) => {
      if (item.id === newBooking.id) {
        return newBooking;
      }
      return item;
    });
    setAllApartments(newList);
  };
  const openDeleteModal = (id: number) => {
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  if (allApartments === undefined) {
    return <Spinner />;
  }
  return (
    <>
      <div className="d-flex justify-content-between notificationbox mb-3">
        <div className="w-75">{notification}</div>
        <CButton
          className="w-10 "
          color="success"
          onClick={() => setShowAdd(true)}>
          <b>+ Hinzufügen</b>
        </CButton>
      </div>

      <AddApartmentModal
        visible={showAdd}
        onClose={() => setShowAdd(false)}
        onSuccess={() => {
          // eslint-disable-next-line no-restricted-globals
          location.reload();
        }}
      />
      <DeleteApartmentModal
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSuccess={() => {
          const filteredApartments = allApartments?.filter(
            (elem) => !(elem.id === deleteId),
          );
          setAllApartments(filteredApartments);
          showNotification('Apartment gelöscht!', 'danger');
          setDeleteId(undefined);
        }}
        id={deleteId}
      />

      <UpdateApartmentModal
        visible={showUpdateModal}
        onClose={() => {
          setShowUpdateModal(false);
          setUpdateItemFocus(undefined);
        }}
        onSuccess={() => {
          showNotification('Apartment aktualisiert', 'warning');
        }}
        updateList={updateLocalList}
        apartment={updateItemFocus || null}
      />

      <CDataTable
        items={allApartments}
        fields={fields}
        hover
        itemsPerPage={15}
        border
        tableFilter
        scopedSlots={{
          // eslint-disable-next-line react/display-name
          created: (item: IApartment) => {
            const { created } = item;
            return (
              <td>
                <Moment format="DD.MM.YYYY">{created}</Moment>
              </td>
            );
          },
          // eslint-disable-next-line react/display-name
          action: (item: IApartment) => {
            return (
              <td className="py-2">
                <CButton
                  className="mx-2"
                  color="info"
                  size="sm"
                  onClick={() => {
                    openUpdateModal(item);
                  }}>
                  Update
                </CButton>
                <CButton
                  className="mx-2"
                  color="danger"
                  size="sm"
                  onClick={() => {
                    openDeleteModal(item.id);
                  }}>
                  Löschen
                </CButton>
              </td>
            );
          },
        }}
      />
    </>
  );
};
export default ApartmentList;
