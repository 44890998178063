import {
  CButton,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import ApiClientApartment, {
  IApartment,
} from '../../../API/ApiClientApartment';

interface Props {
  visible: boolean;
  onClose: (x: boolean) => void;
  onSuccess: CallableFunction;
  apartment: IApartment | null;
  updateList: (x: IApartment) => void;
}
const UpdateApartmentModal: React.FC<Props> = ({
  visible,
  onClose,
  onSuccess,
  apartment,
  updateList,
}: Props) => {
  const [newName, setNewName] = useState(apartment?.name);
  const [newFloor, setNewFloor] = useState(apartment?.floor);
  const [newStreet, setNewStreet] = useState(apartment?.street);
  const [newZip, setNewZip] = useState(apartment?.zip);
  const [newCity, setNewCity] = useState(apartment?.city);

  useEffect(() => {
    setNewName(apartment?.name);
    setNewFloor(apartment?.floor);
    setNewStreet(apartment?.street);
    setNewZip(apartment?.zip);
    setNewCity(apartment?.city);
  }, [apartment]);

  const handleUpdate = async () => {
    if (apartment?.id && newName) {
      const res = await ApiClientApartment.updateApartment(
        apartment.id,
        newName,
        newFloor || null,
        newStreet || null,
        newZip || null,
        newCity || null,
      );
      onClose(false);
      updateList(res);
      onSuccess();
    }
  };
  return (
    <CModal
      centered
      show={visible}
      onClose={() => {
        onClose(false);
      }}>
      <CModalHeader closeButton>Apartment anpassen</CModalHeader>
      {apartment && (
        <CModalBody>
          <CFormGroup>
            <CLabel className="mt-2" htmlFor="nf-name">
              Name:
            </CLabel>
            <CInput
              type="text"
              value={newName || ''}
              id="nf-name"
              title="nf-name"
              placeholder="Name"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewName(e.target.value);
              }}
            />
          </CFormGroup>

          <CFormGroup>
            <CLabel className="mt-2" htmlFor="nf-floor">
              Etage:
            </CLabel>
            <CInput
              type="text"
              value={newFloor || ''}
              id="nf-floor"
              title="nf-floor"
              placeholder="Etage"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewFloor(e.target.value);
              }}
            />
          </CFormGroup>

          <CFormGroup>
            <CLabel className="mt-2" htmlFor="nf-street">
              Straße + Nr.:
            </CLabel>
            <CInput
              type="text"
              value={newStreet || ''}
              id="nf-street"
              title="nf-street"
              placeholder="Straße + Nr."
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewStreet(e.target.value);
              }}
            />
          </CFormGroup>

          <CFormGroup>
            <CLabel className="mt-2" htmlFor="nf-zip">
              PLZ:
            </CLabel>
            <CInput
              type="text"
              value={newZip || ''}
              id="nf-zip"
              title="nf-zip"
              placeholder="PLZ"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewZip(e.target.value);
              }}
            />
          </CFormGroup>

          <CFormGroup>
            <CLabel className="mt-2" htmlFor="nf-city">
              Ort:
            </CLabel>
            <CInput
              type="text"
              value={newCity || ''}
              id="nf-city"
              title="nf-city"
              placeholder="Ort"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewCity(e.target.value);
              }}
            />
          </CFormGroup>
        </CModalBody>
      )}
      <CModalFooter>
        <CButton
          color="primary"
          onClick={() => {
            handleUpdate();
          }}>
          Anpassen
        </CButton>
        <CButton
          color="secondary"
          onClick={() => {
            onClose(false);
          }}>
          Abbrechen
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default UpdateApartmentModal;
