import { CButton, CDataTable } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import ApiClientUser, { IUser } from '../../API/ApiClientUser';
import useNotification from '../../Hooks/useNotification';
import Spinner from '../shared/Spinner';
import AddUserModal from './modals/AddUserModal';
import DeleteUserModal from './modals/DeleteUserModal';
import UpdateUserModal from './modals/UpdateUserModal';

const UserList: React.FC = () => {
  const [allUser, setAllUser] = useState<IUser[] | undefined>();

  const [showAdd, setShowAdd] = useState(false);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateItemFocus, setUpdateItemFocus] =
    useState<{
      id: number;
      name: string;
      email: string;
    }>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number>();

  const [notification, showNotification] = useNotification();

  const fields = [
    { key: 'name', label: 'Name', _style: { width: '30%' } },
    { key: 'email', label: 'Email', _style: { width: '30%' } },
    { key: 'action', label: '', _style: { width: '30%' } },
  ];
  const fetchUser = async (mounted: boolean) => {
    const user = await ApiClientUser.getAllUser();
    if (mounted) setAllUser(user);
  };
  useEffect(() => {
    let didMount = true;
    fetchUser(didMount);
    return () => {
      didMount = false;
    };
  }, []);

  const openUpdateModal = (user: IUser) => {
    setUpdateItemFocus({
      id: user.id,
      name: user.name ? user.name : '',
      email: user.email,
    });
    setShowUpdateModal(true);
  };
  const updateLocalList = (newUser: IUser) => {
    const newList = allUser?.map((item) => {
      if (item.id === newUser.id) {
        return newUser;
      }
      return item;
    });
    setAllUser(newList);
  };
  const openDeleteModal = (id: number) => {
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  if (allUser === undefined) {
    return <Spinner />;
  }
  return (
    <>
      <div className="d-flex justify-content-between notificationbox mb-3">
        <div className="w-75">{notification}</div>
        <CButton
          className="w-10 "
          color="success"
          onClick={() => setShowAdd(true)}>
          <b>+ Hinzufügen</b>
        </CButton>
      </div>

      <AddUserModal
        visible={showAdd}
        onClose={() => setShowAdd(false)}
        onSuccess={() => {
          // eslint-disable-next-line no-restricted-globals
          location.reload();
        }}
      />
      <DeleteUserModal
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSuccess={() => {
          const filteredBooking = allUser?.filter(
            (elem) => !(elem.id === deleteId),
          );
          setAllUser(filteredBooking);
          showNotification('Benutzer gelöscht', 'danger');
          setDeleteId(undefined);
        }}
        id={deleteId}
      />

      <UpdateUserModal
        visible={showUpdateModal}
        onClose={() => setShowUpdateModal(false)}
        onSuccess={() => {
          showNotification('Benutzer aktualisiert', 'warning');
        }}
        updateList={updateLocalList}
        id={updateItemFocus?.id}
        name={updateItemFocus?.name}
        email={updateItemFocus?.email}
      />

      <CDataTable
        items={allUser}
        fields={fields}
        hover
        itemsPerPage={15}
        border
        scopedSlots={{
          // eslint-disable-next-line react/display-name
          action: (item: IUser) => {
            return (
              <td className="py-2">
                <CButton
                  className="mx-2"
                  color="info"
                  size="sm"
                  onClick={() => {
                    openUpdateModal(item);
                  }}>
                  Update
                </CButton>
                <CButton
                  className="mx-2"
                  color="danger"
                  size="sm"
                  onClick={() => {
                    openDeleteModal(item.id);
                  }}>
                  Delete
                </CButton>
              </td>
            );
          },
        }}
      />
    </>
  );
};

export default UserList;
