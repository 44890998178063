/*eslint-disable*/
import React, { ReactNode } from 'react';
import PropTypes from 'prop-types';
import TokenStorage from '../API/TokenStorage';
import Login from './Login/Login';
import Header from './Header/Header';
import Sidebar from './Sidebar/Sidebar';
import { useLocation } from 'react-router-dom';

interface IProps {
  children: ReactNode;
}
export default function Layout({ children }: IProps) {
  if (!TokenStorage.isAuthenticated()) {
    return <Login />;
  }

  const location = useLocation();

  return (
    <div className="c-app c-default-layout">
      <Sidebar />
      <div className="c-wrapper">
        <Header />
        <div className="c-body">{children}</div>
      </div>
    </div>
  );
}

Layout.propTypes = { children: PropTypes.element.isRequired };
