/*eslint-disable*/
import axios from 'axios';
import TokenStorage from './TokenStorage';

export const baseUrl = process.env.REACT_APP_API_URL;

axios.defaults.baseURL = baseUrl;

export type TokenResponse = {
  token: string;
  refresh_token: string;
};

export default class ApiClient {
  static login = async (
    username: string,
    password: string,
  ): Promise<boolean> => {
    const response = await axios.post<TokenResponse>('/login', {
      username,
      password,
    });

    const { data } = response;

    TokenStorage.storeToken(data.token);
    TokenStorage.storeRefreshToken(data.refresh_token);

    return true;
  };

  static refreshToken = async (refreshToken: string): Promise<boolean> => {
    const response = await axios.post<TokenResponse>('/token/refresh', {
      refresh_token: refreshToken,
    });

    const { data } = response;

    TokenStorage.storeToken(data.token);
    TokenStorage.storeRefreshToken(data.refresh_token);

    return true;
  };
}
