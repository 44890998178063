import { CAlert } from '@coreui/react';
import React, { useCallback, useState } from 'react';

const useNotification = (): [CAlert | undefined, CallableFunction] => {
  const [notification, setNotification] =
    useState<CAlert | undefined>(undefined);

  const showNotification = useCallback(
    (text: string, color: 'danger' | 'success' | 'warning') => {
      setNotification(
        <CAlert id="alert" color={color} show={3}>
          {text}
        </CAlert>,
      );
      setTimeout(() => {
        setNotification(undefined);
      }, 4000);
    },
    [],
  );

  return [notification, showNotification];
};
export default useNotification;
