import { CButton, CHeader, CHeaderNav, CHeaderNavItem } from '@coreui/react';
import React from 'react';
import TokenStorage from '../../API/TokenStorage';

const Header: React.FC = () => {
  const handleLogout = () => {
    TokenStorage.clear();
    document.location.reload();
  };

  return (
    <CHeader colorScheme="dark " className="d-flex justify-content-end">
      <CHeaderNav>
        <CHeaderNavItem className="px-3">
          <CButton color="primary" onClick={handleLogout}>
            Logout
          </CButton>
        </CHeaderNavItem>
      </CHeaderNav>
    </CHeader>
  );
};

export default Header;
