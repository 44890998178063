import {
  CButton,
  CFormGroup,
  CInput,
  CInputCheckbox,
  CInputGroup,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react';
import React, { useState } from 'react';
import PhoneInput from 'react-phone-input-2';
import 'react-phone-input-2/lib/style.css';
import ReactLanguageSelect from 'react-languages-select';
import ApiClientBooking from '../../../API/ApiClientBooking';
import ApartmentDropDown from '../components/ApartmentDropDown';

import 'react-languages-select/css/react-languages-select.css';

import '../style.scss';

interface Props {
  visible: boolean;
  onClose: (x: boolean) => void;
  onSuccess: () => void;
}

const AddBookingModal: React.FC<Props> = ({
  visible,
  onClose,
  onSuccess,
}: Props) => {
  // mobileNumber without +
  const [mobileNumber, setMobileNumber] = useState<string>();
  const [name, setName] = useState<string | undefined>();
  const [email, setEmail] = useState<string | undefined>();
  const [bookingDate, setBookingDate] = useState<string | undefined>();
  const [bookingEndDate, setBookingEndDate] = useState<string | undefined>();
  const [language, setLanguage] = useState<string | undefined>('de');
  const [apartment, setApartment] = useState<number | undefined>();
  const [numberOfGuests, setNumberOfGuests] = useState<number | undefined>();
  const [extensionPossible, setExtensionPossible] = useState<boolean>(false);
  const [fileB64, setFileB64] = useState<string | undefined>();
  const [errors, setErrors] = useState('');

  const handleCloseModal = () => {
    setName(undefined);
    setMobileNumber('');
    setBookingDate('');
    setBookingEndDate('');
    setLanguage('');
    setApartment(undefined);
    setNumberOfGuests(undefined);
    setExtensionPossible(false);
    setErrors('');
    onClose(false);
  };

  const handleSubmit = async () => {
    if (
      mobileNumber &&
      bookingDate &&
      bookingEndDate &&
      name &&
      email &&
      language &&
      apartment &&
      numberOfGuests
    ) {
      if (bookingDate > bookingEndDate) {
        setErrors('Das Enddatum darf nicht vor dem Buchungsdatum liegen!');
      } else {
        await ApiClientBooking.createBooking(
          bookingDate,
          bookingEndDate,
          `+${mobileNumber}`,
          name,
          email,
          language.toLowerCase(),
          apartment,
          extensionPossible,
          numberOfGuests,
          fileB64,
        );

        onSuccess();
        onClose(false);
      }
    } else {
      setErrors('Bitte alle notwendigen Felder ausfüllen!');
    }
  };
  const getBase64 = (file: File): Promise<string> => {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsBinaryString(file);
      reader.onload = () =>
        resolve(reader.result ? btoa(reader.result.toString()) : '');
      reader.onerror = (error) => reject(error);
    });
  };
  return (
    <CModal
      size="xl"
      show={visible}
      onClose={() => {
        handleCloseModal();
      }}>
      <CModalHeader closeButton>
        <CModalTitle>Buchung erstellen</CModalTitle>
      </CModalHeader>

      <CModalBody>
        {errors && <p className="text-danger">{errors}</p>}
        <CFormGroup className="inputfield">
          <CLabel>Sprache der E-Mails:</CLabel>
          <ReactLanguageSelect
            defaultLanguage="de"
            searchable
            names="international"
            languages={['en', 'de']}
            onSelect={(e: string) => setLanguage(e)}
          />
        </CFormGroup>

        <CFormGroup className="inputfield">
          <CLabel>Name:</CLabel>
          <CInput
            onBlur={() => (!name ? setErrors('Bitte Namen angeben!') : null)}
            type="text"
            value={name}
            id="nf-name"
            title="nf-name"
            placeholder="Name"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setName(e.target.value);
            }}
          />
          <CLabel className="mt-2">E-Mail:</CLabel>
          <CInput
            onBlur={() => (!name ? setErrors('Bitte E-Mail angeben!') : null)}
            type="email"
            value={email}
            id="nf-name"
            title="nf-name"
            placeholder="E-Mail"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value);
            }}
          />
        </CFormGroup>

        <CLabel>Telefonnummer:</CLabel>
        <PhoneInput
          onBlur={() =>
            !mobileNumber ? setErrors('Bitte Telefonnummer angeben!') : null
          }
          preferredCountries={['de', 'pl', 'bg', 'ro']}
          placeholder="Telefonnummer"
          value={mobileNumber}
          onChange={(phone) => setMobileNumber(phone)}
        />

        <CFormGroup>
          <div className="d-flex col pl-0">
            <div className="mr-2">
              <CLabel className="mt-2">Von:</CLabel>
              <CInput
                required
                onBlur={() =>
                  !bookingDate ? setErrors('Bitte Datum angeben!') : null
                }
                type="date"
                value={bookingDate}
                id="nf-bookingDate"
                title="nf-bookingDate"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setBookingDate(e.target.value);
                }}
              />
            </div>
            <div>
              <CLabel className="mt-2">Bis:</CLabel>
              <CInput
                required
                onBlur={() =>
                  !bookingDate ? setErrors('Bitte Datum angeben!') : null
                }
                type="date"
                value={bookingEndDate}
                id="nf-bookingendDate"
                title="nf-bookingendDate"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setBookingEndDate(e.target.value);
                }}
              />
            </div>
          </div>
        </CFormGroup>

        <CFormGroup>
          <div className="d-flex col pl-0">
            <div className="mr-2">
              <CLabel className="mt-2">Zahl der Gäste:</CLabel>
              <CInput
                required
                onBlur={() =>
                  !numberOfGuests
                    ? setErrors('Bitte Zahl der Gäste angeben!')
                    : null
                }
                type="number"
                value={numberOfGuests}
                id="nf-numberOfGuests"
                title="nf-numberOfGuests"
                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                  setNumberOfGuests(parseInt(e.target.value, 10));
                }}
              />
            </div>
          </div>
        </CFormGroup>

        <CFormGroup className="inputfield">
          <CLabel>Apartment:</CLabel>

          <ApartmentDropDown changeValue={setApartment} />
        </CFormGroup>

        <CFormGroup>
          <p className="mt-2 mb-0">Anhang(.pdf):</p>

          <input
            type="file"
            accept=".pdf"
            id="nf-name"
            title="nf-name"
            placeholder="Name"
            onChange={async (e) => {
              setFileB64(await getBase64(e.target.files![0]));
            }}
          />
        </CFormGroup>

        <CFormGroup className="inputfield">
          <CInputGroup style={{ marginLeft: '1.25rem' }}>
            <CInputCheckbox
              id="nf-extensionPossible"
              label="Verlängerung möglich?"
              checked={extensionPossible}
              onChange={async () => {
                setExtensionPossible(!extensionPossible);
              }}
            />
            <CLabel>Verlängerung möglich?</CLabel>
          </CInputGroup>
        </CFormGroup>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={() => handleCloseModal()}>
          Abbrechen
        </CButton>
        <CButton color="primary" onClick={handleSubmit}>
          Buchung erstellen
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default AddBookingModal;
