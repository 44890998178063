import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from '@coreui/react';
import React from 'react';
import ApiClientUser from '../../../API/ApiClientUser';

interface Props {
  visible: boolean;
  onClose: (x: boolean) => void;
  onSuccess: CallableFunction;
  id: number | undefined;
}
const DeleteUserModal: React.FC<Props> = ({
  visible,
  onClose,
  onSuccess,
  id,
}: Props) => {
  const handleDelete = async () => {
    if (id) {
      await ApiClientUser.deleteUser(id);
      onClose(false);
      onSuccess();
    }
  };
  return (
    <CModal
      centered
      show={visible}
      onClose={() => {
        onClose(false);
      }}>
      <CModalHeader closeButton>Benutzer Löschen</CModalHeader>
      <CModalBody>Diesen Benutzer löschen?</CModalBody>
      <CModalFooter>
        <CButton
          color="danger"
          id="delete"
          onClick={() => {
            handleDelete();
          }}>
          Löschen
        </CButton>
        <CButton
          color="secondary"
          onClick={() => {
            onClose(false);
          }}>
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default DeleteUserModal;
