import {
  CButton,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react';
import React, { useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import ApiClientUser from '../../../API/ApiClientUser';

interface Props {
  visible: boolean;
  onClose: (x: boolean) => void;
  onSuccess: () => void;
}
const AddUserModal: React.FC<Props> = ({
  visible,
  onClose,
  onSuccess,
}: Props) => {
  const [name, setName] = useState<string | undefined>();
  const [email, setEmail] = useState<string>();
  const [password1, setPassword1] = useState<string>();
  const [password2, setPassword2] = useState<string>();
  const [errors, setErrors] = useState('');

  const handleCloseModal = () => {
    setEmail(undefined);
    setName(undefined);
    setPassword1('');
    setPassword2('');
    setErrors('');
    onClose(false);
  };
  const handleSubmit = async () => {
    if (
      password1 &&
      password1 !== '' &&
      password1 === password2 &&
      name &&
      email
    ) {
      await ApiClientUser.createUser(name, email, password1);
      onSuccess();
      onClose(false);
    } else {
      setErrors('Überprüfe die Eingaben!');
    }
  };
  return (
    <CModal
      size="xl"
      show={visible}
      onClose={() => {
        handleCloseModal();
      }}>
      <CModalHeader closeButton>
        <CModalTitle>Benutzer erstellen!</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {errors && <p className="text-danger">{errors}</p>}
        <CLabel>Name:</CLabel>
        <CInput
          onBlur={() => (!name ? setErrors('Bitte Namen angeben!') : null)}
          type="text"
          value={name}
          id="nf-name"
          title="nf-name"
          onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
            setName(e.target.value);
          }}
        />

        <CFormGroup>
          <CLabel className="mt-2">Email:</CLabel>
          <CInput
            onBlur={() => (!email ? setErrors('Bitte Email angeben!') : null)}
            type="email"
            value={email}
            id="nf-email"
            title="nf-email"
            placeholder="Email"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setEmail(e.target.value);
            }}
          />
        </CFormGroup>
        <CFormGroup>
          <CLabel className="mt-2">Passwort:</CLabel>
          <CInput
            onBlur={() => (!name ? setErrors('Bitte Passwort angeben!') : null)}
            type="password"
            value={password1}
            id="nf-password1"
            title="nf-password1"
            placeholder="Password"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPassword1(e.target.value);
            }}
          />
          <CLabel className="mt-2">Passwort wiederhohlen:</CLabel>
          <CInput
            onBlur={() =>
              password1 !== password2
                ? setErrors('Die Passwörter stimmen nicht überein')
                : null
            }
            type="password"
            value={password2}
            id="nf-password2"
            title="nf-password2"
            placeholder="Passwort wiederhohlen"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setPassword2(e.target.value);
            }}
          />
        </CFormGroup>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={() => handleCloseModal()}>
          Close
        </CButton>
        <CButton color="primary" onClick={handleSubmit}>
          Erstellen
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default AddUserModal;
