import {
  CAlert,
  CButton,
  CCard,
  CCardBody,
  CCol,
  CContainer,
  CForm,
  CInput,
  CInputGroup,
  CInputGroupPrepend,
  CInputGroupText,
  CRow,
} from '@coreui/react';
import CIcon from '@coreui/icons-react';
import React, { useState } from 'react';
import ApiClient from '../../API/ApiClientAuth';

const Login: React.FC = () => {
  const [username, setUsername] = useState('');
  const [password, setPassword] = useState('');
  const [errors, setErrors] = useState<string[]>([]);
  const [loginInProgress, setLoginInProgress] = useState(false);

  const submit = async (event: React.FormEvent) => {
    event.preventDefault();

    setLoginInProgress(true);

    const newErrors = [];

    if (!username) {
      newErrors.push('username');
    }

    if (!password) {
      newErrors.push('password');
    }

    setErrors(newErrors);

    if (newErrors.length === 0) {
      try {
        const success = await ApiClient.login(username, password);

        if (success) {
          document.location.reload();
        }
      } catch (e) {
        newErrors.push('loginFailed');
        setErrors(newErrors);
      }

      setLoginInProgress(false);
    } else {
      setLoginInProgress(false);
    }
  };

  return (
    <div className="Login c-app c-default-layout flex-row align-items-center">
      <CContainer>
        <CRow className="justify-content-center">
          <CCol sm="10" md="8" lg="6" xl="4">
            <CCard>
              <CCardBody>
                <CForm onSubmit={(event) => submit(event)}>
                  <div className="text-center mb-2">
                    <img src="/logo.png" alt="HOR" width="150" />
                  </div>

                  <h3 className="mb-3 text-center">Management Login</h3>

                  {errors.includes('loginFailed') && (
                    <CAlert color="danger">Login failed.</CAlert>
                  )}

                  {errors.includes('username') && (
                    <CAlert id="alert" color="danger">
                      Enter E-Mail
                    </CAlert>
                  )}

                  <CInputGroup className="mb-3">
                    <CInputGroupPrepend>
                      <CInputGroupText>
                        <CIcon name="cil-at" />
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput
                      type="text"
                      placeholder="E-Mail"
                      autoComplete="username"
                      id="username"
                      value={username}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setUsername(event.target.value)
                      }
                    />
                  </CInputGroup>
                  {errors.includes('password') && (
                    <CAlert id="alert" color="danger">
                      Enter Password
                    </CAlert>
                  )}
                  <CInputGroup className="mb-4">
                    <CInputGroupPrepend>
                      <CInputGroupText>
                        <CIcon name="cil-lock-locked" />
                      </CInputGroupText>
                    </CInputGroupPrepend>
                    <CInput
                      id="password"
                      type="password"
                      placeholder="Password"
                      autoComplete="current-password"
                      value={password}
                      onChange={(event: React.ChangeEvent<HTMLInputElement>) =>
                        setPassword(event.target.value)
                      }
                    />
                  </CInputGroup>

                  <CButton
                    color="primary"
                    className="px-4 w-100"
                    type="submit"
                    disabled={loginInProgress}
                    size="lg">
                    Login
                  </CButton>
                </CForm>
              </CCardBody>
            </CCard>
          </CCol>
        </CRow>
      </CContainer>
    </div>
  );
};

export default Login;
