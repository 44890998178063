import {
  CSidebar,
  CSidebarBrand,
  CSidebarNav,
  CSidebarNavItem,
} from '@coreui/react';
import React from 'react';

const Sidebar: React.FC = () => {
  return (
    <CSidebar fixed colorScheme="dark">
      <CSidebarBrand className="d-md-down-none" to="/">
        Homes of Ruhr
      </CSidebarBrand>
      <CSidebarNav>
        <CSidebarNavItem icon="cil-List" to="/" name="Buchungen" />
        <CSidebarNavItem icon="cil-home" to="/apartments" name="Apartments" />
        <CSidebarNavItem icon="cil-user" to="/users" name="Benutzer" />
      </CSidebarNav>
    </CSidebar>
  );
};
export default Sidebar;
