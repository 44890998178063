import { CSpinner } from '@coreui/react';
import React from 'react';

const Spinner: React.FC = () => {
  return (
    <div className="d-flex w-100 h-100 justify-content-center align-items-center">
      <CSpinner />
    </div>
  );
};

export default Spinner;
