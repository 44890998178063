import { CContainer } from '@coreui/react';
import React from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import './App.scss';
import Layout from './Components/Layout';
import Apartment from './Pages/Apartment';
import Booking from './Pages/Booking';
import User from './Pages/User';

const App: React.FC = () => {
  return (
    <Router>
      <Layout>
        <CContainer>
          <Switch>
            <Route path="/" exact>
              <Booking />
            </Route>
            <Route path="/apartments" exact>
              <Apartment />
            </Route>
            <Route path="/users" exact>
              <User />
            </Route>
          </Switch>
        </CContainer>
      </Layout>
    </Router>
  );
};

export default App;
