import {
  CButton,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CModalTitle,
} from '@coreui/react';
import React, { useState } from 'react';
import 'react-phone-input-2/lib/style.css';
import ApiClientApartment from '../../../API/ApiClientApartment';

interface Props {
  visible: boolean;
  onClose: (x: boolean) => void;
  onSuccess: () => void;
}
const AddApartmentModal: React.FC<Props> = ({
  visible,
  onClose,
  onSuccess,
}: Props) => {
  const [name, setName] = useState<string | null>(null);
  const [floor, setFloor] = useState<string | null>(null);
  const [street, setStreet] = useState<string | null>(null);
  const [zip, setZip] = useState<string | null>(null);
  const [city, setCity] = useState<string | null>(null);
  const [errors, setErrors] = useState('');

  const handleCloseModal = () => {
    setName(null);
    setFloor(null);
    setStreet(null);
    setZip(null);
    setCity(null);
    setErrors('');
    onClose(false);
  };
  const handleSubmit = async () => {
    if (name) {
      await ApiClientApartment.createApartment(name, floor, street, zip, city);
      onSuccess();
      onClose(false);
    } else {
      setErrors('Bitte alle Notwendigen Felder ausfüllen!');
    }
  };
  return (
    <CModal
      size="lg"
      show={visible}
      onClose={() => {
        handleCloseModal();
      }}>
      <CModalHeader closeButton>
        <CModalTitle>Apartment erstellen!</CModalTitle>
      </CModalHeader>
      <CModalBody>
        {errors && <p className="text-danger">{errors}</p>}
        <CFormGroup>
          <CLabel className="mt-2">Name:</CLabel>
          <CInput
            onBlur={() => (!name ? setErrors('Bitte Namen angeben!') : null)}
            type="text"
            value={name || ''}
            id="nf-name"
            title="nf-name"
            placeholder="Name"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setName(e.target.value);
            }}
          />
        </CFormGroup>

        <CFormGroup>
          <CLabel className="mt-2">Etage:</CLabel>
          <CInput
            onBlur={() => (!floor ? setErrors('Bitte Etage angeben!') : null)}
            type="text"
            value={floor || ''}
            id="nf-floor"
            title="nf-floor"
            placeholder="Etage"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setFloor(e.target.value);
            }}
          />
        </CFormGroup>

        <CFormGroup>
          <CLabel className="mt-2">Straße + Nr.:</CLabel>
          <CInput
            onBlur={() =>
              !street ? setErrors('Bitte Straße + Nr. angeben!') : null
            }
            type="text"
            value={street || ''}
            id="nf-street"
            title="nf-street"
            placeholder="Straße + Nr."
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setStreet(e.target.value);
            }}
          />
        </CFormGroup>

        <CFormGroup>
          <CLabel className="mt-2">PLZ:</CLabel>
          <CInput
            onBlur={() => (!zip ? setErrors('Bitte PLZ angeben!') : null)}
            type="text"
            value={zip || ''}
            id="nf-zip"
            title="nf-zip"
            placeholder="PLZ"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setZip(e.target.value);
            }}
          />
        </CFormGroup>

        <CFormGroup>
          <CLabel className="mt-2">Ort:</CLabel>
          <CInput
            onBlur={() => (!city ? setErrors('Bitte Ort angeben!') : null)}
            type="text"
            value={city || ''}
            id="nf-city"
            title="nf-city"
            placeholder="Ort"
            onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
              setCity(e.target.value);
            }}
          />
        </CFormGroup>
      </CModalBody>
      <CModalFooter>
        <CButton color="secondary" onClick={() => handleCloseModal()}>
          Abbrechen
        </CButton>
        <CButton color="primary" onClick={handleSubmit}>
          Erstellen
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default AddApartmentModal;
