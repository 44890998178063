import { CCard } from '@coreui/react';
import React from 'react';

interface Props {
  children: React.ReactNode;
}
const CardWrapper: React.FC<Props> = ({ children }: Props) => {
  return (
    <CCard className="m-4 p-2" id="ct">
      {children}
    </CCard>
  );
};

export default CardWrapper;
