import { CCardHeader, CCardBody } from '@coreui/react';
import React from 'react';
import CardWrapper from '../Components/shared/CardWrapper';
import UserList from '../Components/User/UserList';

const User: React.FC = () => {
  return (
    <CardWrapper>
      <CCardHeader>
        <h3>Benutzer</h3>
      </CCardHeader>
      <CCardBody>
        <UserList />
      </CCardBody>
    </CardWrapper>
  );
};

export default User;
