import axios from 'axios';
import TokenStorage from './TokenStorage';

export const baseUrl = process.env.REACT_APP_API_URL;
export type IApartment = {
  id: number;
  name: string;
  floor: string | null;
  street: string | null;
  zip: string | null;
  city: string | null;
  created: string;
};
axios.defaults.baseURL = baseUrl;

export default class ApiClientApartment {
  static getAllApartments = async (): Promise<Array<IApartment>> => {
    const response = await axios.get(
      '/apartment',
      await TokenStorage.getAuthentication(),
    );
    const { data } = response;

    return data;
  };

  static deleteApartment = async (id: number): Promise<boolean> => {
    const { data } = await axios.delete(
      `/apartment/${id}`,
      await TokenStorage.getAuthentication(),
    );
    return data;
  };

  static createApartment = async (
    name: string,
    floor: string | null,
    street: string | null,
    zip: string | null,
    city: string | null,
  ): Promise<IApartment> => {
    const { data } = await axios.post(
      '/apartment',
      {
        name,
        floor,
        street,
        zip,
        city,
      },
      await TokenStorage.getAuthentication(),
    );
    return data;
  };

  static updateApartment = async (
    id: number,
    name: string,
    floor: string | null,
    street: string | null,
    zip: string | null,
    city: string | null,
  ): Promise<IApartment> => {
    const { data } = await axios.put(
      `/apartment/${id}`,
      {
        name,
        floor,
        street,
        zip,
        city,
      },
      await TokenStorage.getAuthentication(),
    );
    return data;
  };
}
