import axios from 'axios';
import { IApartment } from './ApiClientApartment';
import TokenStorage from './TokenStorage';

export const baseUrl = process.env.REACT_APP_API_URL;
export type IBooking = {
  id: number;
  mobileNumber: string;
  bookingDate: string;
  bookingDateEnd: string;
  email: string;
  name: string;
  emailNotification: string;
  language: string;
  firstNotification: string;
  secondNotification: string;
  thirdNotification: string;
  apartment: IApartment;
  numberOfGuests: number | null;
  extensionPossible: boolean;
  hasAttachment: boolean;
  created: string;
  createdBy: {
    id: number;
    email: string;
  };
};
axios.defaults.baseURL = baseUrl;

export default class ApiClientBooking {
  static getAllBookings = async (): Promise<Array<IBooking>> => {
    const response = await axios.get(
      '/booking',
      await TokenStorage.getAuthentication(),
    );

    const { data } = response;

    return data;
  };

  static deleteBooking = async (id: number): Promise<boolean> => {
    const { data } = await axios.delete(
      `/booking/${id}`,
      await TokenStorage.getAuthentication(),
    );
    return data;
  };

  static createBooking = async (
    bookingDate: string,
    bookingDateEnd: string,
    mobileNumber: string,
    name: string,
    email: string,
    language = 'de',
    apartment: number,
    extensionPossible: boolean,
    numberOfGuests?: number | null,
    attachment?: string,
  ): Promise<IBooking> => {
    const { data } = await axios.post(
      '/booking',
      {
        bookingDate,
        bookingDateEnd,
        mobileNumber,
        name,
        email,
        language,
        apartment,
        numberOfGuests,
        extensionPossible,
        attachment,
      },
      await TokenStorage.getAuthentication(),
    );
    return data;
  };

  static updateBooking = async (
    id: number,
    bookingDate: string,
    bookingDateEnd: string,
    mobileNumber: string,
    name: string,
    email: string,
    language = 'de',
    apartment: number,
    extensionPossible: boolean,
    numberOfGuests?: number | null,
  ): Promise<IBooking> => {
    const { data } = await axios.put(
      `/booking/${id}`,
      {
        bookingDate,
        bookingDateEnd,
        mobileNumber,
        name,
        email,
        language,
        apartment,
        extensionPossible,
        numberOfGuests,
      },
      await TokenStorage.getAuthentication(),
    );
    return data;
  };
}
