import { CCardBody, CCardHeader } from '@coreui/react';
import React from 'react';
import BookingList from '../Components/Booking/BookingList';
import CardWrapper from '../Components/shared/CardWrapper';

const Booking: React.FC = () => {
  return (
    <CardWrapper>
      <CCardHeader>
        <h3>Buchungen</h3>
      </CCardHeader>
      <CCardBody>
        <BookingList />
      </CCardBody>
    </CardWrapper>
  );
};

export default Booking;
