import {
  CButton,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
} from '@coreui/react';
import React from 'react';
import ApiClientApartment from '../../../API/ApiClientApartment';

interface Props {
  visible: boolean;
  onClose: (x: boolean) => void;
  onSuccess: CallableFunction;
  id: number | undefined;
}
const DeleteApartmentModal: React.FC<Props> = ({
  visible,
  onClose,
  onSuccess,
  id,
}: Props) => {
  const handleDelete = async () => {
    if (id) {
      await ApiClientApartment.deleteApartment(id);
      onClose(false);
      onSuccess();
    }
  };
  return (
    <CModal
      centered
      show={visible}
      onClose={() => {
        onClose(false);
      }}>
      <CModalHeader closeButton>Apartment Löschen</CModalHeader>
      <CModalBody>Dieses Apartment löschen?</CModalBody>
      <CModalFooter>
        <CButton
          color="danger"
          id="delete"
          onClick={() => {
            handleDelete();
          }}>
          Löschen
        </CButton>
        <CButton
          color="secondary"
          onClick={() => {
            onClose(false);
          }}>
          Abbrechen
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default DeleteApartmentModal;
