import {
  CButton,
  CCol,
  CFormGroup,
  CInput,
  CLabel,
  CModal,
  CModalBody,
  CModalFooter,
  CModalHeader,
  CRow,
} from '@coreui/react';
import React, { useEffect, useState } from 'react';
import ApiClientUser, { IUser } from '../../../API/ApiClientUser';
import './styles.scss';

interface Props {
  visible: boolean;
  onClose: (x: boolean) => void;
  onSuccess: CallableFunction;
  id: number | undefined;
  name: string | undefined;
  email: string | undefined;
  updateList: (x: IUser) => void;
}
const UpdateUserModal: React.FC<Props> = ({
  visible,
  onClose,
  onSuccess,
  id,
  name,
  email,
  updateList,
}: Props) => {
  const [newName, setNewName] = useState<string | undefined>('');
  const [newEmail, setNewEmail] = useState<string | undefined>('');
  const [newPassword1, setNewPassword1] = useState('');
  const [newPassword2, setNewPassword2] = useState('');
  const [error, setError] = useState('');
  useEffect(() => {
    setNewName(name);
    setNewEmail(email);
  }, [email, name]);
  const handleUpdate = async () => {
    if (id && newEmail) {
      if (
        newEmail &&
        email !== '' &&
        newPassword1 &&
        newPassword1 !== '' &&
        newPassword1 === newPassword2
      ) {
        const res = await ApiClientUser.updateUser(
          id,
          newEmail,
          newName || '',
          newPassword1,
        );
        updateList(res);
        onSuccess();
        onClose(false);
      } else if (!newPassword1 && newEmail) {
        const res = await ApiClientUser.updateUser(id, newEmail, newName || '');
        updateList(res);
        onSuccess();
        onClose(false);
      }
    } else {
      setError('Email ist notwendig');
    }
  };
  return (
    <CModal
      centered
      show={visible}
      onClose={() => {
        onClose(false);
      }}>
      <CModalHeader closeButton>Benutzer anpassen</CModalHeader>
      {email && (
        <CModalBody>
          <CFormGroup>
            <CLabel htmlFor="nf-mobilenumber">Name:</CLabel>
            <CInput
              type="text"
              value={newName}
              id="nf-name"
              title="nf-name"
              placeholder="Name"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewName(e.target.value);
              }}
            />
          </CFormGroup>
          <CFormGroup>
            <CLabel className="mt-2" htmlFor="nf-date">
              Email:
            </CLabel>
            <CInput
              type="text"
              value={newEmail}
              id="nf-email"
              title="nf-email"
              placeholder="Email"
              onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                setNewEmail(e.target.value);
              }}
            />
          </CFormGroup>
          <CFormGroup>
            <p className="info">
              Passwort nur angeben falls es geändert werden soll.
            </p>
            <CRow>
              <CCol md={6} className="d-flex flex-row">
                <div>
                  <CLabel htmlFor="nf-password">Neues Passwort:</CLabel>
                  <CInput
                    type="password"
                    value={newPassword1}
                    id="nf-password1"
                    title="nf-password1"
                    placeholder="Passwort"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setNewPassword1(e.target.value);
                    }}
                  />
                </div>
              </CCol>
              <CCol md={6}>
                <div>
                  <CLabel htmlFor="nf-name">
                    Neues Passwort wiederhohlen:
                  </CLabel>
                  <CInput
                    type="password"
                    value={newPassword2}
                    id="nf-password2"
                    title="nf-password2"
                    placeholder="Passwort wiederhohlen"
                    onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                      setNewPassword2(e.target.value);
                    }}
                    onBlur={() => {
                      if (newPassword1 !== newPassword2) {
                        setError('Passwörter stimmen nicht überein');
                      }
                    }}
                  />
                </div>
              </CCol>
              {error && <p className="text-danger ml-3">{error}</p>}
            </CRow>
          </CFormGroup>
        </CModalBody>
      )}
      <CModalFooter>
        <CButton
          color="primary"
          onClick={() => {
            handleUpdate();
          }}>
          Update
        </CButton>
        <CButton
          color="secondary"
          onClick={() => {
            onClose(false);
          }}>
          Cancel
        </CButton>
      </CModalFooter>
    </CModal>
  );
};

export default UpdateUserModal;
