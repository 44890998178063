import axios from 'axios';
import TokenStorage from './TokenStorage';

export const baseUrl = process.env.REACT_APP_API_URL;
export type IUser = {
  id: number;
  name?: string;
  email: string;
  created: string;
  createdBy: {
    id: number;
    email: string;
  };
};
axios.defaults.baseURL = baseUrl;

export default class ApiClientUser {
  static getAllUser = async (): Promise<Array<IUser>> => {
    const response = await axios.get(
      '/user',
      await TokenStorage.getAuthentication(),
    );
    const { data } = response;

    return data;
  };

  static deleteUser = async (id: number): Promise<boolean> => {
    const { data } = await axios.delete(
      `/user/${id}`,
      await TokenStorage.getAuthentication(),
    );
    return data;
  };

  static createUser = async (
    name: string,
    email: string,
    password: string,
  ): Promise<IUser> => {
    const { data } = await axios.post(
      '/user',
      { password, email, name },
      await TokenStorage.getAuthentication(),
    );
    return data;
  };

  static updateUser = async (
    id: number,
    email: string,
    name?: string,
    password?: string,
  ): Promise<IUser> => {
    const { data } = await axios.put(
      `/user/${id}`,
      { email, name, password },
      await TokenStorage.getAuthentication(),
    );
    return data;
  };
}
