import { CButton, CDataTable } from '@coreui/react';
import React, { useEffect, useState } from 'react';
import Moment from 'react-moment';
import ApiClientBooking, { IBooking } from '../../API/ApiClientBooking';
import useNotification from '../../Hooks/useNotification';

import Spinner from '../shared/Spinner';
import AddBookingModal from './modals/AddBookingModal';
import DeleteBookingModal from './modals/DeleteBookingModal';
import UpdateBookingModal from './modals/UpdateBookingModal';
import './style.scss';

const BookingList: React.FC = () => {
  const [allBookings, setAllBookings] = useState<IBooking[] | undefined>();

  const [showAdd, setShowAdd] = useState(false);

  const [showUpdateModal, setShowUpdateModal] = useState(false);
  const [updateItemFocus, setUpdateItemFocus] = useState<IBooking>();
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [deleteId, setDeleteId] = useState<number>();

  const [notification, showNotification] = useNotification();

  const fields = [
    { key: 'name', label: 'Name', _style: { width: '20%' } },
    { key: 'bookingDate', label: 'Buchungsdatum', _style: { width: '17%' } },
    { key: 'mobileNumber', label: 'Telefonnummer', _style: { width: '20%' } },
    {
      key: 'firstNotification',
      label: 'SMS 1',
      _style: { width: '10%' },
    },
    {
      key: 'secondNotification',
      label: 'SMS 2',
      _style: { width: '10%' },
    },
    {
      key: 'thirdNotification',
      label: 'SMS 3',
      _style: { width: '10%' },
    },
    { key: 'action', label: '', _style: { width: '13%' } },
  ];
  const fetchBookings = async (mounted: boolean) => {
    const bookings = await ApiClientBooking.getAllBookings();
    if (mounted) setAllBookings(bookings);
  };
  useEffect(() => {
    let didMount = true;
    fetchBookings(didMount);
    return () => {
      didMount = false;
    };
  }, []);

  const openUpdateModal = (booking: IBooking) => {
    setUpdateItemFocus(booking);
    setShowUpdateModal(true);
  };
  const updateLocalList = (newBooking: IBooking) => {
    const newList = allBookings?.map((item) => {
      if (item.id === newBooking.id) {
        return newBooking;
      }
      return item;
    });
    setAllBookings(newList);
  };
  const openDeleteModal = (id: number) => {
    setDeleteId(id);
    setShowDeleteModal(true);
  };

  if (allBookings === undefined) {
    return <Spinner />;
  }
  return (
    <>
      <div className="d-flex justify-content-between notificationbox mb-3">
        <div className="w-75">{notification}</div>
        <CButton
          className="w-10 "
          color="success"
          onClick={() => setShowAdd(true)}>
          <b>+ Hinzufügen</b>
        </CButton>
      </div>

      <AddBookingModal
        visible={showAdd}
        onClose={() => setShowAdd(false)}
        onSuccess={() => {
          // eslint-disable-next-line no-restricted-globals
          location.reload();
        }}
      />
      <DeleteBookingModal
        visible={showDeleteModal}
        onClose={() => setShowDeleteModal(false)}
        onSuccess={() => {
          const filteredBooking = allBookings?.filter(
            (elem) => !(elem.id === deleteId),
          );
          setAllBookings(filteredBooking);
          showNotification('Buchung gelöscht', 'danger');
          setDeleteId(undefined);
        }}
        id={deleteId}
      />

      {updateItemFocus && (
        <UpdateBookingModal
          visible={showUpdateModal}
          onClose={() => {
            setShowUpdateModal(false);
            setUpdateItemFocus(undefined);
          }}
          onSuccess={() => {
            showNotification('Buchung aktualisiert', 'warning');
          }}
          updateList={updateLocalList}
          booking={updateItemFocus}
        />
      )}

      <CDataTable
        items={allBookings.sort((a, b) => {
          if (a.bookingDate < b.bookingDate) return 1;
          if (a.bookingDate > b.bookingDate) return -1;
          return 0;
        })}
        fields={fields}
        hover
        itemsPerPage={15}
        border
        sorter
        tableFilter
        scopedSlots={{
          // eslint-disable-next-line react/display-name
          bookingDate: (item: IBooking) => {
            const { bookingDate, bookingDateEnd } = item;
            return (
              <td>
                <Moment format="DD.MM.YYYY">{bookingDate}</Moment>
                {bookingDateEnd && (
                  <>
                    <b> - </b>
                    <Moment format="DD.MM.YYYY">{bookingDateEnd}</Moment>
                  </>
                )}
              </td>
            );
          },
          // eslint-disable-next-line react/display-name
          firstNotification: (item: IBooking) => {
            const { firstNotification } = item;

            return (
              <td>
                {firstNotification !== null && (
                  <Moment format="DD.MM.YYYY">{firstNotification}</Moment>
                )}
              </td>
            );
          },
          // eslint-disable-next-line react/display-name
          secondNotification: (item: IBooking) => {
            const { secondNotification } = item;

            return (
              <td>
                {secondNotification !== null && (
                  <Moment format="DD.MM.YYYY">{secondNotification}</Moment>
                )}
              </td>
            );
          },
          // eslint-disable-next-line react/display-name
          thirdNotification: (item: IBooking) => {
            const { thirdNotification } = item;

            return (
              <td>
                {thirdNotification !== null && (
                  <Moment format="DD.MM.YYYY">{thirdNotification}</Moment>
                )}
              </td>
            );
          },
          // eslint-disable-next-line react/display-name
          action: (item: IBooking) => {
            return (
              <td className="py-2">
                <CButton
                  className="mx-2"
                  color="info"
                  size="sm"
                  onClick={() => {
                    openUpdateModal(item);
                  }}>
                  Update
                </CButton>
                <CButton
                  className="mx-2"
                  color="danger"
                  size="sm"
                  onClick={() => {
                    openDeleteModal(item.id);
                  }}>
                  Delete
                </CButton>
              </td>
            );
          },
        }}
      />
    </>
  );
};

export default BookingList;
