import { AxiosRequestConfig } from 'axios';
import jwt_decode from 'jwt-decode';
import moment from 'moment';
/* eslint-disable import/no-cycle */
import ApiClient from './ApiClientAuth';

export type TokenData = {
  iat: number;
  exp: number;
};

export default class TokenStorage {
  private static readonly LOCAL_STORAGE_TOKEN = 'token';

  private static readonly LOCAL_STORAGE_REFRESH_TOKEN = 'refresh_token';

  public static isAuthenticated(): boolean {
    return this.getToken() !== null;
  }

  public static async getAuthentication(): Promise<AxiosRequestConfig> {
    const tokenData = this.getTokenData();
    const now = moment().unix();

    if (tokenData.exp < now + 300) {
      const refreshToken = this.getRefreshToken();

      if (refreshToken) {
        try {
          await ApiClient.refreshToken(refreshToken);
        } catch (e) {
          this.clear();
          document.location.href = '/';

          return {};
        }

        const tokenData2 = this.getTokenData();

        if (tokenData2.exp < now + 300) {
          this.clear();
          document.location.href = '/';

          return {};
        }
      }
    }

    return {
      headers: { Authorization: `Bearer ${this.getToken()}` },
    };
  }

  public static storeToken(token: string): void {
    localStorage.setItem(TokenStorage.LOCAL_STORAGE_TOKEN, token);
  }

  public static storeRefreshToken(refreshToken: string): void {
    localStorage.setItem(
      TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN,
      refreshToken,
    );
  }

  public static clear(): void {
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_TOKEN);
    localStorage.removeItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  public static getToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_TOKEN);
  }

  private static getRefreshToken(): string | null {
    return localStorage.getItem(TokenStorage.LOCAL_STORAGE_REFRESH_TOKEN);
  }

  public static getTokenData(): TokenData {
    const token = TokenStorage.getToken();

    if (!token) {
      throw new Error('token not found');
    }

    const decoded: TokenData = jwt_decode(token);

    return decoded;
  }
}
